<span class="pull-right">
  <a href="https://github.com/beyerleinf/bcc-calculator" target="_blank">Issues? Find me on GitHub</a>
</span>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h1 class="text-center">Block Check Character Calculator</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-danger" role="alert" *ngIf="error">
        <p>{{ error }}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label for="inputMode">Input Mode</label>
        <select class="form-control" id="inputMode" [(ngModel)]="inputMode">
          <option [ngValue]="'ascii'">ASCII String</option>
          <option [ngValue]="'hex'">Hex String</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="inputMode === 'ascii'">
    <div class="col-md-12">
      <div class="input-group">
        <input
          [(ngModel)]="asciiString"
          type="text"
          class="form-control font-mono"
          placeholder="ASCII String"
          aria-describedby="btn-addon"
        />
        <span class="input-group-btn" id="btn-addon">
          <button class="btn btn-default" type="button" (click)="calculate()">Calculate</button>
        </span>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="inputMode === 'hex'">
    <div class="col-md-12">
      <div class="input-group">
        <input
          [(ngModel)]="hexString"
          type="text"
          class="form-control font-mono"
          placeholder="Hexadecimal String"
          aria-describedby="btn-addon"
        />
        <span class="input-group-btn" id="btn-addon">
          <button class="btn btn-default" type="button" (click)="calculate()">Calculate</button>
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="input-group font-mono">
        <span class="input-group-addon" id="hex-addon">
          0x
        </span>
        <input
          type="text"
          readonly
          class="form-control"
          value="{{ bccVal }}"
          placeholder="Calculated Block Check Character"
          aria-describedby="hex-addon"
        />
      </div>
      <br />
      <div *ngIf="bccBinary">
        <p>Binary representation:</p>
        <code>{{ bccBinary }}</code>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-tutorial></app-tutorial>
    </div>
  </div>
</div>
